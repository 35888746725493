@import "cards.css";

.doudizhu-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
   
    #gameboard-background {
        width: 100%;
        height: 100%;
        background-image: url("./images/gameboard.png");
        background-repeat: no-repeat;
        background-size: 100% 125%;
        background-position: bottom;
        
        &.blur-background {
            filter: blur(3px);
            pointer-events: none;
        }
    }

    .played-card-area {
        font-size: 12px;
        width: 300px;
        display: flex;
        justify-content: center;

        .timer.fade-in {
            visibility: hidden;
            opacity: 0;
        }

        .timer.fade-out {
            visibility: hidden;
            transition: visibility 0.1s, opacity 0.05s;
            opacity: 0;
        }
        
        .main-player-action-wrapper {
            display: flex;
            align-items: center;
            
            button {
                font-weight: bolder;
                border-radius: 40px;
            }
        }

        .timer {
            visibility: visible;
            transition: visibility 0s, opacity 0.2s, transform 0.3s;
            opacity: 1;
            width: 51px;
            height: 60px;
            .timer-text {
                color: #303133;
                transform: translateY(5px);
                font-size: 23px;
                font-weight: bold;
                text-shadow: 0 2px 2px #909399;
                line-height: 55px;
            }
            text-align: center;
            background-image: url("./images/timer.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }

        .non-card {
            display: table;
            width: 280px;
            height: 80px;

            span {
                display: table-cell;
                vertical-align: middle;
                text-align: center;
                font-size: 23px;
                font-weight: bold;
                text-shadow: 0 2px 2px rgba(0, 0, 0, 0.87);
                color: #F2F6FC;
            }
        }
    }

    .player-hand-placeholder {
        font-size: 16px;
        display: table;

        span {
            color: whitesmoke;
            display: table-cell;
            vertical-align: middle;
            text-align: center;
        }
    }

    .player-info {
        font-size: 16px;
        width: 130px;
        height: 130px;
        div {
            text-align: center;
        }
        span {
            white-space: pre;
            text-align: center;
        }
    }

    .player-main-area {
        background-color: initial;
    }

    #left-player {
        position: absolute;
        left: 10px;
        top: 10px;

        .player-main-area {
            width: 300px;
            height: 130px;
            font-size: 10px;

            .player-hand-up {
                position: absolute;
                top: 0;
                margin-left: 150px;
                width: 150px;
            }

            .player-hand-down {
                position: absolute;
                top: 50px;
                margin-left: 150px;
                width: 150px;
            }

            .player-hand-placeholder {
                width: 150px;
                height: 130px;
                position: absolute;
                top: 0;
                margin-left: 130px;
            }
        }

        .played-card-area {
            position: relative;
            left: 20px;
            top: 20px;
        }
    }

    #right-player {
        position: absolute;
        right: 10px;
        top: 10px;

        .player-main-area {
            width: 300px;
            height: 130px;
            font-size: 10px;

            .player-hand-up {
                position: absolute;
                top: 0;
                margin-right: 150px;
                width: 150px;
            }

            .player-hand-down {
                position: absolute;
                top: 50px;
                margin-right: 150px;
                width: 150px;
            }

            .player-hand-placeholder {
                width: 150px;
                height: 130px;
                position: absolute;
                top: 0;
                margin-right: 130px;
            }

            .player-info {
                float: right;
            }
        }

        .played-card-area {
            position: relative;
            right: 20px;
            top: 20px;
        }
    }

    #bottom-player {
        position: absolute;
        bottom: 10px;
        left: 50%;
        margin-left: -290px;

        .player-main-area {
            width: 580px;
            height: 130px;
            position: relative;

            .player-hand {
                position: absolute;
                top: 0;
                padding-left: 20px;
                margin-left: 130px;
                width: 450px;
                font-size: 14px;
            }

            .player-hand-placeholder {
                width: 150px;
                height: 130px;
                position: absolute;
                top: 0;
                padding-left: 20px;
                margin-left: 130px;
            }
        }

        .played-card-area {
            width: 100%;
            position: relative;
            transform: translateY(-10px);

            .playingCards ul.hand {
                margin-bottom: 0;
            }

            .non-card {
                height: 70px;
                //transform: translateY(-25px);
            }
        }
    }

    .playingCards {
        a.card.selected {
            bottom: 1em;
        }
    }
}

.doudizhu-statistic-table {
    .MuiTableHead-root {
        th.MuiTableCell-root.MuiTableCell-head {
            font-weight: bolder;
        }
    }
}