@import url('https://fonts.googleapis.com/css?family=Arvo&display=swap');

body {
  background-color: rgb(241, 241, 241);
  margin: 0;
  color: #606266;
  font-family: 'Arvo', 'Rockwell', monospace, PingFangSC-Regular, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.unselectable {
  pointer-events: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.el-message {
  z-index: 9999;
}

.citation {
  font-family: 'Rockwell', monospace, PingFangSC-Regular, sans-serif;
  margin-top: 15px;
  padding: 6px;

  -webkit-user-select: text;
  -ms-user-select: text;
  user-select: text;

  a {
    &:visited {
      color: blue;
    }
  }

  pre {
    margin-top: 5px;
    padding: 10px;
    border-radius: 5px;
    color: #64686d;
    background-color: rgb(255, 255, 255);
    border: solid 1px #e9e9e9; 
  }
}



#upload-model-note {
  a {
    color: #3f51b5;
    text-decoration: none;

    &:visited {
      color: #3f51b5;
    }
  }
}